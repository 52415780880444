<template>
  <div
    class="table-responsive scroll-custom"
  >
    <table
      id="sorting-table"
      class="table table-striped table-hover"
    >
      <thead>
        <tr>
          <th
            v-if="canSelect"
            class="cursor-pointer"
          >
            <input
              v-model="clicked"
              type="checkbox"
              @click="selectAll($event)"
            >
          </th>
          <th
            v-if="rankedPath"
            class="cursor-pointer"
          >
            Rank
          </th>
          <template
            v-for="field in fields"
          >
            <th
              v-if="field.checked"
              :key="`ac-${field.order}`"
              style="padding:0.72rem 2rem;"
            >
              <!-- :class="{'cursor-pointer': fetchPath && fetchPath != '-'}" -->
              <h6
                :id="`field-${field.id}`"
                class="control-arrow header-table"
                :style="{width: field.width + 'px'}"
              >
                <!-- @click="sort(field.name, field.label)" -->
                {{ field.label ? field.label : field.name }}
                <span :style="{ display: sField != field.name ? 'none' : '' }">
                  <i
                    data-feather="arrow-up"
                  />
                </span>
                <span :style="{ display: orderAux != field.name ? 'none' : '' }">
                  <i
                    data-feather="arrow-down"
                  />
                </span>
              </h6>
              <b-tooltip
                :key="`field-custom-${field.id}`"
                placement="topleft"
                boundary="document"
                triggers="hover"
                :target="`field-${field.id}`"
                :title="field.tooltip"
              />
            </th>
          </template>
          <th
            v-if="showActionsColumn"
            class="text-end"
          >
            {{ actionsColumnTitle }}
          </th>
        </tr>
      </thead>
      <tbody v-if="!paginatorLoading">
        <tr
          v-for="field, index in data"
          :key="`aa-${field.id}-${index}`"
        >
          <td
            v-if="canSelect"
          >
            <input
              :checked="
                clicked || (field.user_id ? selectedItems.findIndex(x => x.user_id === field.user_id) >= 0 : (field.wos_id ? selectedItems.findIndex(x => x.wos_id === field.wos_id) >= 0 : selectedItems.findIndex(x => x.id === field.id) >= 0))
              "
              type="checkbox"
              @click="select($event.target.checked, field)"
            >
          </td>
          <td
            v-if="rankedPath"
          >
            <input
              :checked="clicked"
              type="number"
              min="0"
              class="form-control"
              :value="field.rank"
              @change="setRank($event.target.value, field)"
            >
          </td>
          <td
            v-for="property, index in reorderData(field)"
            :key="`ab-${index}`"
            class="force-black"
            style="padding:0.72rem 2rem;"
            v-html="property"
          />
          <slot :field="field" />
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td
            colspan="6"
            class="text-center"
          >
            <div
              class="loading custom-loading m-auto"
            >
              <div class="effect-1 effects" />
              <div class="effect-2 effects" />
              <div class="effect-3 effects" />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <TableColumns />
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { BTooltip } from 'bootstrap-vue'
import TableColumns from '../offcanvas/TableColumns.vue'

export default {
  components: {
    TableColumns,
    BTooltip,
  },
  props: {
    data: { type: [Array, Object], required: true },
    sortedField: { type: String, required: true },
    sortMapping: { type: Object, required: true },
    canSelect: { type: Boolean, required: false, default: false },
    fetchPath: { type: String, required: false, default: '' },
    paginatorLoading: { type: Boolean, required: false, default: false },
    rankedPath: { type: String, required: false, default: null },
    selectedItems: { type: Array, required: false, default: () => [] },
    showActionsColumn: { type: Boolean, required: false, default: true },
    actionsColumnTitle: { type: String, required: false, default: 'Actions' },
    groupingTable: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      orderAux: null,
      sField: '',
      clicked: false,
    }
  },
  computed: {
    ...mapGetters({
      fields: 'modals/tableSettingsFields',
      filters: 'filters/filters',
      perPage: 'pagination/perPage',
    }),
  },
  watch: {
    perPage() {
      this.clicked = false
    },
    'filters.reorder': function (newValue) {
      if (this.groupingTable && newValue && this.filters.dir) {
        if (this.filters.dir.value === 'DESC') {
          this.orderAux = Object.keys(this.sortMapping).find(key => this.sortMapping[key] === newValue.value)
          this.sField = null
        } else {
          this.sField = Object.keys(this.sortMapping).find(key => this.sortMapping[key] === newValue.value)
          this.orderAux = null
        }
      }
    },
    'filters.dir': function (newValue) {
      if (this.groupingTable && newValue && this.filters.reorder) {
        if (newValue.value === 'DESC') {
          this.orderAux = Object.keys(this.sortMapping).find(key => this.sortMapping[key] === this.filters.reorder.value)
          this.sField = null
        } else {
          this.sField = Object.keys(this.sortMapping).find(key => this.sortMapping[key] === this.filters.reorder.value)
          this.orderAux = null
        }
      }
    },
  },
  mounted() {
    if (this.filters && ['reorder', 'dir'].every(e => Object.keys(this.filters).includes(e))) {
      if (this.filters.dir.value === 'DESC') {
        this.orderAux = Object.keys(this.sortMapping).find(key => this.sortMapping[key] === this.filters.reorder.value)
        this.sField = null
      } else {
        this.sField = Object.keys(this.sortMapping).find(key => this.sortMapping[key] === this.filters.reorder.value)
        this.orderAux = null
      }
    } else {
      this.sField = this.sortedField
    }
  },
  methods: {
    reorderData(data) {
      const properties = []
      if (this.fields !== undefined) {
        this.fields.forEach(item => {
          if (!item.checked) return
          const value = data[this.sortMapping[item.name]]
          if (!value && this.sortMapping[item.name] && this.sortMapping[item.name].split('.')[1]) {
            if (data[this.sortMapping[item.name].split('.')[0]]) {
              let aux = data[this.sortMapping[item.name].split('.')[0]][this.sortMapping[item.name].split('.')[1]]
              if (this.sortMapping[item.name].split('.')[2] && aux) {
                aux = aux[this.sortMapping[item.name].split('.')[2]]
              }

              if (aux === false || aux === true) {
                if (aux) {
                  properties.push('<span class="badge badge-light-success">Yes</span>')
                } else {
                  properties.push('<span class="badge badge-light-danger">No</span>')
                }
              } else if (Array.isArray(aux)) {
                const values = aux.map(el => (el.code ? el.code : el.name))
                properties.push(values.join(', '))
              } else {
                properties.push(aux)
              }
            } else {
              properties.push('--')
            }
          } else if (value === false || value === true) {
            if (value) {
              properties.push('<span class="badge badge-light-success">Yes</span>')
            } else {
              properties.push('<span class="badge badge-light-danger">No</span>')
            }
          } else if (Array.isArray(value)) {
            if (item.name === 'Top') {
              properties.push(value.map(el => el.custom_name).join(',<br>'))
            } else if (item.name === 'Subsection') {
              const values = value.map(el => el.name)
              properties.push(values.join(', '))
            } else {
              const values = value.filter(el => !el.parent_id).map(el => (el.code ? el.code : el.name))
              properties.push(values.join(', '))
            }
          } else if (value === '') {
            properties.push('--')
          } else if (!value && value !== 0) {
            properties.push('--')
          } else {
            properties.push(value)
          }
        })
      }

      return properties
    },
    sortConvos(field, sortDir) {
      if (sortDir) {
        this.orderAux = this.sField
        this.sField = null
      }
      this.data.sort((a, b) => {
        if (sortDir) {
          if (a[field] > b[field]) {
            return -1
          }
          if (a[field] < b[field]) {
            return 1
          }
          return 0
        }

        if (a[field] < b[field]) {
          return -1
        }
        if (a[field] > b[field]) {
          return 1
        }
        return 0
      })
    },
    async sort(field, label) {
      if (!this.fetchPath || this.fetchPath === '-') {
        return
      }

      const sort = this.sField === field
      if (!sort) { // ASC
        this.sField = field
        this.orderAux = null
      } else { // DESC
        this.orderAux = this.sField
        this.sField = null
      }

      const value = this.sortMapping[field]

      this.$emit('togglePaginator', true)

      this.$store.dispatch('filters/saveFilters', { value: { value, name: `Order field: ${label || field}` }, field: 'reorder' })
      this.$store.dispatch('filters/saveFilters', { value: { value: sort ? 'DESC' : 'ASC', name: `Order direction: ${sort ? 'DESC' : 'ASC'}` }, field: 'dir' })
      await this.$store.dispatch(this.fetchPath, this.filters).then(() => {
        this.$emit('togglePaginator', false)
      })

      // this.sortConvos(value, sort)

      feather.replace({
        width: 14,
        height: 14,
      })
    },
    selectAll(event) {
      if (event.target.checked) {
        this.clicked = true
        this.$emit('selectAll')
      } else {
        this.clicked = false
        this.$emit('selectAll', false)
      }
    },
    select(click, item) {
      this.$emit('selectItem', { item, click })
    },
    setRank(rank, item) {
      this.$store.dispatch('convo/setRank', { rank, item }).then(status => {
        if (status) {
          this.$toastr.error('', 'Something went wrong')
        } else {
          this.$toastr.success('', 'Ranked successfully')
        }
      })
    },
  },
}
</script>
